#meimg{
  width: 300px;
  height: 300px;
  object-fit: cover;
  position: relative;
  margin: auto;
  border-radius: 60% 10% 50% 30%;
}

#intro{
  position: relative;
  top:5vh;
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin: auto;
  /* background:red; */
}

#des{
  top:5vh;
  animation: animate 1s ease forwards;
  margin: auto;
  height: fit-content;
  width: 70vw;
  position: relative;
  text-align: center;
  line-height: 30px;
  font-size: 135%;
  border-radius: 5px;
  padding: 10px;
  font-family: monospace;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="400" height="200"><rect width="100%" height="100%" fill="rgb(220, 220, 220)"/><path d="M0 130 Q200 50, 400 130 V200 H0 Z" fill="rgba(255, 255, 255, 0.3)"/></svg>');

}

@keyframes animate {
  0%{
    line-height: 0px;
  }

  100%{
    line-height: 30px;
  }
}

/* **************** SORRY ********************** */
#error {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="none"><rect width="100%" height="100%" fill="%23f0ecec"/><circle cx="100" cy="100" r="90" fill="%23e0d3d3"/><path d="M 0,130 Q 50,90 100,130 T 200,130 V 200 H 0 Z" fill="%23b0a1a1"/></svg>');
  background-size: cover;
  height: 50vh;
  width: 50vw;
  border-radius: 10px;
  margin: auto;
  text-align: center;
  color:white;
  position: relative;
  top: 10vh;
}

#home{
  width: 50%;
  border:none;
}

#error > *{
  position: relative;
  top: 40%;
}



/* ************** PROJECTS **************** */
#projectC{
  display: flex;
}

#comingSoon{
  padding: 10px;
  border-radius: 10px;
  margin: auto;
  position: relative;
  top:5vh;
  font-size: 160%;
  text-align: center;
  font-family: monospace;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="400" height="200"><rect width="100%" height="100%" fill="lightgray"/><circle cx="100" cy="150" r="40" fill="rgba(100, 100, 255, 0.1)"/><circle cx="300" cy="150" r="40" fill="rgba(255, 100, 100, 0.1)"/><path d="M0 120 Q200 80, 400 120 V200 H0 Z" fill="rgba(255, 255, 255, 0.2)"/></svg>');

}


/* *********** SERVICES ************** */
#services{
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  position: relative;
  top:5vh;
}

#que{
  width: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  animation: que ease 2s forwards;
  text-align: center;
}

@keyframes que{
  0%{
    width: 0px;
  }

  50%{width: 50%;}

  100%{
    width: fit-content;
  }
}

#services > div{
  margin: auto;
  width: fit-content;
  height: fit-content;
  text-align: center;
  font-family: monospace;
  border-radius: 10px;
  box-shadow: 2px 2px 2px lightgray;
  padding: 2vw;
  /* background: rgba(211, 211, 211, 0.515); */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="200" height="200"><rect width="100%" height="100%" fill="lightgray"/><line x1="10" y1="10" x2="190" y2="10" stroke="black" stroke-opacity="0.1" stroke-width="1"/><line x1="10" y1="20" x2="190" y2="20" stroke="black" stroke-opacity="0.1" stroke-width="1"/><line x1="10" y1="30" x2="190" y2="30" stroke="black" stroke-opacity="0.1" stroke-width="1"/><line x1="10" y1="40" x2="190" y2="40" stroke="black" stroke-opacity="0.1" stroke-width="1"/><path d="M0 90 Q50 70, 100 90 T200 90 V200 H0 Z" fill="rgba(255, 255, 255, 0.5)"/></svg>');

}

#services > div > *{
  text-decoration: none;
}

/* ***************** CONTACTS *********/

#contact{
  display: flex;
  flex-direction: column;
  width: 80vw;
  position: relative;
  top:5vh;
  margin: auto;
}

#sociallinks{
  display: flex;
  margin: auto;
  /* padding: 10px; */
}

#sociallinks > div{
  border-radius: 5px;
  font-family: monospace;
  /* background: white; */
  padding: 10px;
  text-align: center;
  margin: auto 40px 30px 10px;
}

#sociallinks > div > *{
  text-decoration: none;
}

#dm{
  width: fit-content;
  margin: auto;
}

fieldset{
  padding: 10px;
  border-radius: 5px;
  border: rgb(196, 196, 196) 1px solid;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="400" height="300"><rect width="100%" height="100%" fill="lightgray"/><circle cx="300" cy="200" r="50" fill="rgba(100, 100, 255, 0.1)"/><circle cx="100" cy="100" r="40" fill="rgba(255, 100, 100, 0.1)"/><path d="M0 180 Q200 120, 400 180 V300 H0 Z" fill="rgba(255, 255, 255, 0.2)"/></svg>');

}

label, legend{
  font-weight: bold;
  font-family: monospace;
}

button{
  width: 100%;
  background: rgb(4, 175, 218);
  color:white;
  padding: 5px;
  border-radius: 5px;
}

input, textarea{
  border-radius: 5px;
  border:none;
  width: 40vw;
  font-size: 120%;
  height: 5vh;
}

textarea{
  text-align: center;
  height: 15vh;
}

#mail{
  text-align: center;
  border:1px solid lightgray;
  border-radius: 10px;
  width: fit-content;
  margin: auto;
  padding: 10px;
  background:rgb(237, 237, 237);
}

/* ************ MOBILE ********** */
@media (max-width:768px) {
  #meimg{
    height: 150px;
    width: 150px;
  }

  #des{
    width: 90vw;
    font-size: 120%;
    
  }

  #services{
    top:1vh;
    flex-direction: column;
  }

  #services > div{
    width: 70vw;
    height: fit-content;
    margin: 10px auto 10px auto;
    padding: 10px;
  }

  #sociallinks{
    flex-wrap: wrap;
  }

  #sociallinks> div{
    margin: auto;
  }

  #dm{
    width: 96%;
    top:30px;
    position: relative;
    margin: auto;
  }

  form > input, textarea{
    width: 100%;
    text-align: left;
  }

  #error{
    width: 90vw;
  }

  #thankyou{
    width: 90vw;
  }
}