*{
  padding: 0;
  margin: 0;
}

#headers{
  display: flex;
  /* background: rgb(4, 175, 218); */
  height: 10.5vh;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="400" height="200"><rect width="100%" height="100%" fill="rgb(0, 150, 185)"/><circle cx="100" cy="90" r="40" fill="rgba(255, 255, 255, 0.3)"/><circle cx="300" cy="90" r="40" fill="rgba(255, 255, 255, 0.3)"/><path d="M0 100 Q100 30, 200 100 T400 100 V200 H0 Z" fill="rgba(255, 255, 255, 0.4)"/></svg>');

}

#sublinks{
  margin: auto;
  display: flex;
}

#sublinks > *{
  padding: 2vw;
  text-decoration:none;
  color:white;
}

#logo{
  margin: auto;
}

#logo > *{
  text-decoration:none;
  color:white;
}

body {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"><rect width="100%" height="100%" fill="rgb(241, 241, 241)"/><g stroke="lightgray" stroke-width="0.4"><pattern id="grid" width="20" height="50" patternUnits="userSpaceOnUse"><rect width="50" height="50" fill="none"/><line x1="50" y1="0" x2="50" y2="50"/><line x1="0" y1="50" x2="50" y2="50"/></pattern><rect width="100%" height="100%" fill="url(%23grid)"/></g></svg>');
  background-size: cover;
  /* background-repeat: no-repeat; */
  height: 100vh;
  width: 100vw;
  margin: 0;
}

/* ****************  ABOVE IS HOME ************** */